import ApplicationController from "./application_controller";
import StimulusReflex from "stimulus_reflex";
import interact from "interactjs";

/* good article: https://javascript.info/mouse-drag-and-drop */
export default class extends ApplicationController {
  static targets = ["scrollable"];

  connect() {
    super.connect();
    StimulusReflex.register(this);

    this.boardsstimulusController =
      document.querySelector("#board-workspace").boardsstimulus;

    interact('li.post-item [draggable="true"]').draggable({
      listeners: {
        start: this.dragCardStart.bind(this),
        move: this.dragCard.bind(this),
      },
    });

    interact("li.post-item").dropzone({
      ondrop: function (event) {
        console.log(
          event.relatedTarget.id +
            " was dropped into post-item " +
            event.target.id,
        );

        this.stimulateDropOnCard(event, event.target);
        this.dragCardEnd(event);
      }.bind(this),
    });

    interact(".board-column").dropzone({
      ondrop: function (event) {
        console.log(
          event.relatedTarget.id +
            " was dropped into board-column " +
            event.target.id,
        );
        this.stimulateDropOnColumn(event, event.target);
        this.dragCardEnd(event);
      }.bind(this),
    });

    this.altModifierKeyPressed = false;

    document.addEventListener(
      "keydown",
      function (event) {
        if (event.keyCode == 18) {
          this.altModifierKeyPressed = true;
        }
      }.bind(this),
    );
    document.addEventListener(
      "keyup",
      function (event) {
        if (event.keyCode == 18) {
          this.altModifierKeyPressed = false;
        }
      }.bind(this),
    );
  }
  getBoardsStimulusController() {
    return document.querySelector("#board-workspace").boardsstimulus;
  }

  voidDrag(event) {
    return false;
  }
  dragCardStart(event) {
    event.stopPropagation();
    event.preventDefault();

    this.dragItem = this.getParent(event.target, "cardid", "dataset");

    this.formatDragCard(this.dragItem);

    return true;
  }
  dragCard(event) {
    /*event.stopPropagation();*/
    event.preventDefault();

    if (this.dragItem == undefined) {
      // console.log("this.dragItem is undefined")
      return false;
    }
    /* implement autoscrolling here */
    this.moveDragTwin(event);
    this.highlightCardDragOver(event);
  }
  dragCardEnd(event) {
    event.stopPropagation();
    event.preventDefault();
    this.removeFormatDragCard(this.dragItem);
    this.dragItem = undefined;
  }
  insertDragTwin(draggedCard) {
    // console.log("create dragTwin with id: " + draggedCard.dataset.cardid)
    this.dragTwin = draggedCard.cloneNode(true);
    this.dragTwin.style.width =
      draggedCard.getBoundingClientRect().width + "px";
    this.dragTwin.style.position = "absolute";
    draggedCard.insertAdjacentElement("afterend", this.dragTwin);
  }
  formatDragCard(draggedCard) {
    draggedCard.classList.add("opacity-50");
    if (
      this.dragTwin != undefined &&
      this.dragTwin.dataset.cardid != draggedCard.dataset.cardid
    ) {
      this.insertDragTwin(draggedCard);
    }
  }
  moveDragTwin(event) {
    this.dragCoordinates = this.coordFromEvent(event);
    /*this.dragCoordinates = [
      this.dragCoordinates.page[0] - 125,
      this.dragCoordinates.page[1] - 25,
    ];*/

    if (this.dragTwin == undefined) {
      this.insertDragTwin(this.dragItem);
    }

    this.dragTwin.style.left = this.dragCoordinates.pageX - 125 + "px";
    this.dragTwin.style.top = this.dragCoordinates.pageY - 25 + "px";

    // current scroll position: window.scrollX / window.scrollY
    // screen dimensions: window.screen.availWidth / window.screen.availHeight
    // viewport dimensions: window.visualViewport.width
    // event document coords dragCoordinates
    // event viewport coords event.clientX / event.clientY

    // let logo = document.querySelector("h1");
    let m = 50;
    let hsize = 120;
    let maxXScroll = document.body.scrollWidth - window.visualViewport.width;
    let maxYScroll = document.body.scrollHeight - window.visualViewport.height;
    let ec = this.dragCoordinates;

    // logo.innerText = `${ec.screenX} / ${ec.screenY} [ < ${m + hsize}] || [> ${window.visualViewport.height - ec.y}]`;

    let needsScrollX =
      ec.screenX < m || window.visualViewport.width - ec.screenX < m;
    let needsScrollY =
      ec.screenY < m + hsize || window.visualViewport.height - ec.screenY < m;
    let scrollTo = [window.scrollX, window.scrollY];

    if (needsScrollX) {
      scrollTo[0] =
        this.dragCoordinates.pageX - window.visualViewport.width / 2;
      if (scrollTo[0] <= 0) {
        scrollTo[0] = 0;
      }
      if (scrollTo[0] >= maxXScroll) {
        scrollTo[0] = maxXScroll;
      }
    }
    if (needsScrollY) {
      scrollTo[1] =
        this.dragCoordinates.pageY - window.visualViewport.height / 2;
      if (scrollTo[1] <= 0) {
        scrollTo[1] = 0;
      }
      if (scrollTo[1] >= maxYScroll) {
        scrollTo[1] = maxYScroll;
      }
    }
    window.scroll(scrollTo[0], scrollTo[1]);
  }
  removeFormatDragCard(draggedCard) {
    if (draggedCard != undefined) {
      draggedCard.style.position = "static";
      draggedCard.classList.remove("opacity-50");
    }
    if (this.dragTwin != undefined) {
      this.dragTwin.remove();
    }
  }

  async stimulateDropOnCard(event, dropOnCard) {
    console.log("stimulateDropOnCard");
    // Reflex method:
    // receiveDrop(cardTargetId, new_status, cardid, isTopTarget, copy=false)

    let col = this.getParent(dropOnCard, "board-column").dataset.status;
    let currentDropzone = dropOnCard.querySelector(".card-dropzone");

    if (super.isConnected()) {
      this.getBoardsStimulusController().stimulate(
        "BoardsReflex#receiveDrop",
        event.target,
        dropOnCard.dataset.cardid, // dropTargetId
        col,
        this.dragItem.dataset.cardid,
        this.isTopTargetEvent(event, currentDropzone), // isTopTarget
        this.altModifierKeyPressed,
      );
    } else {
      console.log("no stimulateDrop");

      const response = await this.doFetch(
        "/posts/" + this.dragItem.dataset.cardid + "/move",
        "PATCH",
        JSON.stringify({
          authenticity_token: this.getCsrfToken(),
          dropTarget: dropOnCard.dataset.cardid,
          isTopTarget: this.isTopTargetEvent(event, currentDropzone),
          board_column_id: col,
          remote: true,
        }),
      );
      if (response.ok) {
        location.reload();
        /*
         * use this for further customisation - for now go with reloading the page
        let json_return = await response.json();
        console.log("did move with response: " + JSON.stringify(json_return))
        */
      } else {
        alert("There was an error when trying to move this card!");
      }
    }
  }

  async stimulateDropOnColumn(event, dropOnColumn) {
    console.log("\n\nstimulateDropOnColumn");
    // Reflex method:
    // receiveDrop(cardTargetId, new_status, cardid, isTopTarget, copy=false)

    let col = dropOnColumn.dataset.status;
    if (this.isTopColumnEvent(event, dropOnColumn, true)) {
      console.log("…is Top Column Event");
    } else {
      console.log("…is Bottom Column Event");
    }

    if (super.isConnected()) {
      this.getBoardsStimulusController().stimulate(
        "BoardsReflex#receiveDrop",
        event.target,
        -1, // dropTargetId
        col,
        this.dragItem.dataset.cardid,
        this.isTopColumnEvent(event, dropOnColumn), // isTopTarget
        this.altModifierKeyPressed,
      );
    } else {
      console.log("no stimulateDrop");

      const response = await this.doFetch(
        "/posts/" + this.dragItem.dataset.cardid + "/move",
        "PATCH",
        JSON.stringify({
          authenticity_token: this.getCsrfToken(),
          dropTarget: -1,
          isTopTarget: this.isTopColumnEvent(event, dropOnColumn),
          board_column_id: col,
          remote: true,
        }),
      );
      if (response.ok) {
        location.reload();
        /*
         * use this for further customisation - for now go with reloading the page
        let json_return = await response.json();
        console.log("did move with response: " + JSON.stringify(json_return))
        */
      } else {
        alert("There was an error when trying to move this card!");
      }
    }
  }

  highlightCardDragOver(event) {
    // console.log("\n\n--------------------- highlightCardDragOver");
    let dragTargetCard = this.searchValidDropTarget(event);
    if (!dragTargetCard || dragTargetCard == undefined) {
      return false;
    }
    // console.log("\n\n--------------------- highlightCardDragOver");
    // console.log("\t did find one");
    // console.log("highlightCardDragOver found dragTargetCard " + dragTargetCard.dataset.cardid)
    // console.log("dragTargetCard: " + dragTargetCard);
    let targetDropzone = dragTargetCard.querySelector(".card-dropzone");

    // return if no card was found
    if (!targetDropzone) {
      return false;
    }
    // console.log("\tfound dropzone")
    this.cardDragOver(dragTargetCard);
  }

  searchValidDropTarget(event) {
    event.stopPropagation();
    event.preventDefault();
    // console.log(`searchValidDropTarget for event ${event.type}`);

    if (event.type == "touchmove") {
      var coord = [
        event.touches[0].pageX - window.scrollX,
        event.touches[0].pageY - window.scrollY,
      ];
    } else {
      var coord = [event.pageX - window.scrollX, event.pageY - window.scrollY];
    }

    let dropTargetElements = document.elementsFromPoint(coord[0], coord[1]);

    for (var i = 0; i < dropTargetElements.length; i++) {
      let testElement = this.getParent(
        dropTargetElements[i],
        "cardid",
        "dataset",
      );
      if (
        testElement &&
        testElement.dataset.cardid != this.dragItem.dataset.cardid
      ) {
        /*console.log(
          `did find dropTarget ${testElement.id} at coords ${coord[0]}/${coord[1]}`,
        );*/
        return testElement;
      }
    }
    return false;
  }

  removeAllDragoverHighlights() {
    document.querySelectorAll(".card-dropzone").forEach(
      function (cdrz) {
        this.removeClasses(cdrz, [
          "pt-2",
          "border-top",
          "pb-2",
          "border-bottom",
          "border-5",
        ]);
      }.bind(this),
    );
  }
  cardDragOver(dragTargetCard) {
    // if the drag occured over a new card
    // set this.cardDragOverElement
    if (
      this.cardDragOverElement == undefined ||
      dragTargetCard.id != this.cardDragOverElement.id
    ) {
      if (dragTargetCard.id != this.dragItem.id) {
        this.cardDragOverElement = dragTargetCard;
      }
    }

    this.removeAllDragoverHighlights();

    // Add margin top or bottom depending on event coordinates
    if (this.cardDragOverElement == undefined) {
      return true;
    }
    let currentDropzone =
      this.cardDragOverElement.querySelector(".card-dropzone");
    if (this.isTopTargetEvent(event, currentDropzone)) {
      this.addClasses(currentDropzone, ["pt-2", "border-5", "border-top"]);
    } else {
      this.addClasses(currentDropzone, ["pb-2", "border-5", "border-bottom"]);
    }
  }

  pageDragCoordinates() {
    let scrollX = window.scrollX;
    let scrollY = window.scrollY;
    return [
      this.dragCoordinates.screenX + scrollX,
      this.dragCoordinates.screenY + scrollY,
    ];
  }
  isTopColumnEvent(event, column) {
    let scrollX = window.scrollX;
    let scrollY = window.scrollY;
    let coords = this.pageDragCoordinates();
    let columnRect = column.getBoundingClientRect();
    let columnCards = column.querySelectorAll("li.post-item");

    console.log(`event coordinates on page: ${coords[0]}/${coords[1]}`);
    console.log(
      `column position ${columnRect.x + scrollX}/${columnRect.y + scrollY} r/bottom: ${columnRect.right}/${columnRect.bottom}`,
    );
    // this.isTopTargetEvent(event, column, true);

    // If there are no cards in the column return true
    if (columnCards == undefined || columnCards.length == 0) {
      console.log("no cards found… return false");
      return true;
    }

    let lastCardRect =
      columnCards[columnCards.length - 1].getBoundingClientRect();
    if (coords[1] >= lastCardRect.bottom) {
      return false;
    }
    return true;
  }

  isTopTargetCoords(coords, trg, debug = false) {
    if (trg == undefined || trg === false) {
      return true;
    }

    let trgTop = trg.getBoundingClientRect().top;
    let trgHeight = trg.getBoundingClientRect().height;
    let border = trgTop + trgHeight / 2;

    if (debug) {
      console.log(
        "isTopTargetCoords: event: y:" +
          coords.pageY +
          " trg: y + h : (" +
          trgTop +
          " + " +
          trgHeight +
          ") / 2 = " +
          border,
      );
      if (coords.pageY <= border) {
        console.log("isTopTarget true");
      } else {
        console.log("isTopTarget false");
      }
    }
    return coords.pageY <= border;
  }

  isTopTargetEvent(event, trg, debug = false) {
    return this.isTopTargetCoords(this.coordFromEvent(event), trg, false);
  }

  coordFromEvent(event) {
    if (event.type.match(/touch/)) {
      console.log("\ntouch event");
      return {
        pageX: event.changedTouches[0].pageX,
        pageY: event.changedTouches[0].pageY,
        screenX: event.changedTouches[0].screenX,
        screenY: event.changedTouches[0].screenY,
      };
    }
    if (event.type.match(/drop/)) {
      return this.dragCoordinates;
    }
    // console.log(`\nstandard event ${event.type}`);
    return {
      pageX: event.pageX,
      pageY: event.pageY,
      screenX: event.pageX - window.scrollX,
      screenY: event.pageY - window.scrollY,
    };
  }

  /*
   *
   * Touch stuff
   *
   */

  touchMoveCard(event) {
    event.stopPropagation();
    event.preventDefault();

    let draggedCard = this.getParent(event.target, "cardid", "dataset");
    let isTopTarget = this.isTopTargetEvent(event, draggedCard);
    let coord = this.coordFromEvent(event);

    var card_id = draggedCard.dataset["cardid"];

    this.dragItem = draggedCard;
    this.formatDragCard(this.dragItem);
    this.moveDragTwin(event);
    this.highlightCardDragOver(event);
  }
}
